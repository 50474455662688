import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../images/logo_text.png';
import TryButton from '../button/TryButton';

function RankPage(){
    return (
        <div className="page no-padding-top">
            <Row className="no-margin">
                <Col className="no-padding">
                    <p> Level 1 </p>
                </Col>
            </Row>
        </div>
    );
}
export default RankPage;