import React from 'react';
import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import logo from "../images/logo_text.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from '../form/DatePickerField';
import { Page } from 'react-pdf';
import DatePicker from "react-datepicker";
import { DropdownButton, Dropdown } from 'react-bootstrap';

function Logs() {
	const admin = window.localStorage.getItem("token") != null;
	const token = window.localStorage.getItem("token");
	let userType = window.localStorage.getItem("userType");
	const [location, setLocation] = useState("");
	const [date, setDate] = useState(new Date());
	const [logs, setLogs] = useState([]);

	useEffect(() => {
		axios.get(process.env.REACT_APP_API_URL + '/auth/logs/' + moment(date).utc(true).format('DD-MM-YYYY'), {
			headers: {
				Authorization: `Bearer ${token}`
			}
		}).then((response) => {
			setLogs(response.data);
			console.log(response.data);
		}).catch((error) => {
			console.log(error);
		});
	}, []);

	return (
		<div className="page no-padding-top">
			<h1 className="text-center pt-5">Logs</h1>
			{userType == "OWNER" &&
			<DropdownButton onSelect={(e) => setLocation(e)} title={location == '' ? "Orice locație" : location} size="lg" variant="light">
				<Dropdown.Item eventKey="OTOPENI" >Otopeni</Dropdown.Item>
				<Dropdown.Item eventKey="DOBROESTI">Dobroesti</Dropdown.Item>
			</DropdownButton>
		}
			<div className='d-flex justify-content-center'>
				<div className='w-50 mx-md-5 mx-3 py-4'>
					<DatePicker
						dateFormat="dd/MM/yyyy"
						placeholderText="zi/lună/an"
						className="form-control animate__shakeX"
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						yearDropdownItemNumber={100}
						onFocus={(e) => e.target.readOnly = true}
						selected={(date && new Date(date)) || null}
						onChange={val => {
							setDate(val);
							axios.get(process.env.REACT_APP_API_URL + '/auth/logs/' + moment(val).utc(true).format('DD-MM-YYYY'), {
								headers: {
									Authorization: `Bearer ${token}`
								}
							}).then((response) => {
								setLogs(response.data);
								console.log(response.data);
							}).catch((error) => {
								console.log(error);
							});
						}}
						onYearChange={(date) => setDate(date)}
						onMonthChange={(date) => setDate(date)}
						onWeekSelect={(date) => setDate(date)}
						onDayChange={(date) => setDate(date)}
						openToDate={date}
					/>
				</div>
			</div>
			<div className='mx-3'>
				{logs.filter((log) => log.location === location || console.log(JSON.stringify(log) + "si" + location)).map((log) => 
					<Row key={JSON.stringify(log)} className='w-100 justify-content-center m-0 py-3'>
						<Col xs={4} md={3} className='border border-2 border-warning text-break'>
							<p>{log.user}</p>
						</Col>
						<Col xs={8} md={3} className='border border-2 border-warning'>
							<p>{moment(log.timestamp).format("DD/MM/yyyy - HH:mm:ss")}</p>
						</Col>
						<Col xs={12} md={4} className='border border-2 border-warning'>
							{log.action.split("\n").map((line) => <p>{line}</p>)}
						</Col>
					</Row>
				)}
			</div>
		</div>
	);
}

export default Logs;

