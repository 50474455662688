import React, { useEffect, useState } from 'react';
import axios, { all } from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../images/logo_text.png';
import TryButton from '../button/TryButton';
import ChildPage from '../child/ChildPage';
import { useLocation, useNavigate } from 'react-router-dom';
import DropdownContainer from '../dropdown/Dropdown';
import { Form } from 'react-bootstrap';
import './SearchChild.css';
import Attendance from '../child/Attendance';
import moment from 'moment';
import { to } from 'react-spring';
import check from '../images/check.png';
import absent from '../images/semnx.png';
import WarnIconRed from '../images/warn-red.png';
import WarnIconYellow from '../images/warn-yellow.png';


function getTBDChildren(children) {
  let tbdChildren = [];
  children.forEach((child) => {
    if (child.membership === 'GRATIS' && child.validated == false && !child.hidden && (child.group === 'TBD' || child.time === 'TBD')) {
      tbdChildren.push(child);
    }
  });

  return tbdChildren;
}


function SearchChild() {
    const [scrollPosition, setScrollPosition] = useState(0);
    const navigate = useNavigate();
    const admin = window.localStorage.getItem("token") != null;
    const token = window.localStorage.getItem("token");
    let userType = window.localStorage.getItem("userType");
    const location = useLocation();
    const [children, setChildren] = useState([]);
    const [search, setSearch] = useState("");
    const [selectedChild, setSelectedChild] = useState({});
    const [group, setGroup] = useState('');
    const [time, setTime] = useState('');
    const [attendeeLocation, setAttendeeLocation] = useState('');
    const [showValid, setShowValid] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showHidden, setShowHidden] = useState(false);
    const [showUnhidden, setShowUnhidden] = useState(false);
    const [userLocation, setUserLocation] = useState('');
    const [overview, setOverview] = useState({});

    const [tbdChildren, setTbdChildren] = useState([]);

    const generateTimeOptions = (groupla) => {
      if (groupla === 'Sa') {
          console.log('sambata');
          return ['11:00', '12:00', '13:00', '14:00', '15:00'];
      } else {
          return ['16:00', '17:00', '18:00', '19:00', '20:00'];
      }
  }

    useEffect(() => {
        if(!admin)
            navigate('/');
        if(location.state != null && location.state.search != null) {
            setSearch(location.state.search);
            location.state.search = null
        }

        let userLoc = "";
        if(admin && !userType)
          axios.get(process.env.REACT_APP_API_URL + "/auth/type", {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }).then((res) => {
            window.localStorage.setItem("userType", res.data.userType);
            window.localStorage.setItem("userLoc", res.data.location);
            userType = res.data.userType;
            setUserLocation(res.data.location);
            userLoc = res.data.location;
          }).catch((err) => {
          });
        else if(admin && userType) {
          setUserLocation(window.localStorage.getItem("userLoc"));
        }
        

        if(children.length < 1) {
            axios.get(process.env.REACT_APP_API_URL + "/attend/all/admin", {
                headers: {
                    'Authorization': `Bearer ${token}` 
                }
            }).then(res => {
                setChildren(res.data);
                let selectedChildId = window.localStorage.getItem("selectedChildId");
                if(selectedChildId) {
                  let child = res.data.find((ch) => ch._id == selectedChildId);
                  if(child) {
                    setSelectedChild(child);
                  }
                }

                // Check if there is a child, with free membership, that has TBD group or time
                let tbdChildren = getTBDChildren(res.data); 
                setTbdChildren(tbdChildren);
            });
            axios.get(process.env.REACT_APP_API_URL + "/attend/count", {
              headers: {
                Authorization: `Bearer ${token}`
              }
            }).then((response) => {
              setOverview(response.data);
            }).catch((error) => {
              console.log(error);
            });
        }
        if(JSON.stringify(selectedChild) != "{}") {
            let id = selectedChild._id;
            let newChild = children.find((ch) => ch._id == id);
            if(JSON.stringify(newChild) != JSON.stringify(selectedChild))
                setSelectedChild(newChild);
        }
        if(window.localStorage.getItem("group")) {
            setGroup(window.localStorage.getItem("group"));
            setShowUnhidden(true);
            setShowHidden(false);
        }
        if(window.localStorage.getItem("time")) {
            setTime(window.localStorage.getItem("time"));
        }
        if(window.localStorage.getItem("scrollPosition")) {
            setScrollPosition(window.localStorage.getItem("scrollPosition"));
        }
        if(userType == "OWNER" && window.localStorage.getItem("location")) {
            setAttendeeLocation(window.localStorage.getItem("location"));
        }
        //console.log(children.forEach((child) => console.log(child.cleanName)))

    }, [search, JSON.stringify(selectedChild)]);

  function getClassByMembership(membership) {
    if (membership === 'GRUPA') {
      return "aqua";
    } else if (membership === 'PT') {
      return "violet";
    } else if (membership === 'MINI-GRUPA') {
      return "chartreuse";
    } else if (membership === 'GRATIS') {
      return "orange";
    }
    return "white";
  }

  //vreau sa creez o functie care returneaza true sau false, pe care o voi folosi intr-un filter in felul urmator
  //voi vedea daca copilul primit ca parametru este in grupa si la ora selectata si daca are prezenta pusa in data de azi
  //daca da, returnez true, altfel false
  function checkAttendance(child) {
    if(group === '' || time === '') 
      return false;
    const childGroup = child.group;
    const childTime = child.time;
    let allGroups = childGroup.split('-');
    let allTimes = childTime.split('-');
    let includeGroup = false;
    let includeTime = false;
    const locationMatch = attendeeLocation === '' || child.location.toLowerCase().includes(attendeeLocation.toLowerCase());
    let toReturn = false;
    if(child.reschedule != "" && child.rescheduleDate != null && new Date(child.rescheduleDate).toDateString() == new Date().toDateString()) {
      let reArray = child.reschedule.split("-");
      let firstDay = reArray[0].split(",");
      let secondDay = reArray[1].split(",");
      if (group == secondDay[0] && time == secondDay[1]) {
        allGroups = secondDay[0];
        allTimes = secondDay[1];
        includeGroup = allGroups.includes(group);
        includeTime = allTimes.includes(time);
      }
    } else {
      if(allGroups.length > allTimes.length) {
        if(allGroups.includes(group) && allTimes.includes(time)) {
          includeGroup = true;
          includeTime = true;
        }
      } else {
        //cazul pe mai multe zile si nr de ore egale, de pus un console log pe nume 
        let indexTime = allTimes.indexOf(time);
        let indexGroup = allGroups.indexOf(group);
        if(indexTime === indexGroup && indexTime !== -1 && indexGroup !== -1) {
          includeGroup = true;
          includeTime = true;
        }
      }
    }
    //daca grupa copilului e inclusa in grupa selectata si ora copilului e inclusa in ora selectata
    // console.log("Numele copilului: " + child.name);
    // console.log("Grupa copilului: " + allGroups);
    // console.log("Ora copil: " + allTimes);
    // console.log("Grupa selectata: " + group);
    // console.log("Ora selectata: " + time);

    // console.log("///////////////////////");
    if (includeGroup && includeTime && locationMatch) {
      //daca copilul are prezenta pusa in data de azi
      //console.log("am ajuns aiciii");
      //console.log("Numarul de prezente: " + child.attendanceNo);
      if (child.attendance.length == 0) {
        return false;
      } else {
        child.attendance.forEach((att) => {
          //console.log("Data prezentei: " + moment(att.date).format("DD/MM/yyyy"));
          //console.log("Data de azi: " + moment().format("DD/MM/yyyy"));
          //console.log("///////////////////////");
          if(moment(att.date).format("DD/MM/yyyy") === moment().format("DD/MM/yyyy") && att.status === "PRESENT") {
              console.log("Prezenta copilului gasita: " + att.date);
              //console.log()
              toReturn = true;
              return true;
          }
        });
      }
    }
    return toReturn;
  }
  
  function handleClose() {
    setSelectedChild({});
    window.localStorage.removeItem("selectedChildId");
    if (window.localStorage.getItem("goRanking") == "1") {
      window.localStorage.removeItem("goRanking");
      window.localStorage.setItem("LBLoc", "Otopeni");
      navigate("/");
    } else if (window.localStorage.getItem("goRanking") == "2") {
      window.localStorage.removeItem("goRanking");
      window.localStorage.setItem("LBLoc", "Dobroesti");
      navigate("/");
    }
    setTimeout(function () {
      window.scrollTo(0, scrollPosition);
  },2);
    window.localStorage.removeItem("scrollPosition");
  }

  const handleDropdownChange = (field, selectedOption) => {
    if(selectedOption !== ''){
        setShowUnhidden(true);
        setShowHidden(false);
    }
    if (field === 'day') {
        setGroup(selectedOption);
        window.localStorage.setItem("group", selectedOption);
    } else if (field === 'time') {
        setTime(selectedOption);
        window.localStorage.setItem("time", selectedOption);
    } else if (field === 'location') {
        setAttendeeLocation(selectedOption);
        window.localStorage.setItem("location", selectedOption);
    }
  };

  const filterChildren = (child) => {
    const groupMatch = group === '' || child.group.includes(group);
    const locationMatch = attendeeLocation === '' || child.location.toLowerCase().includes(attendeeLocation.toLowerCase());
    if(group === '') {
      const timeMatch = time === '' || child.time.includes(time);

      return groupMatch && timeMatch && locationMatch;
    } else {
      /*if(child.reschedule != "" && child.rescheduleDate != null && new Date(child.rescheduleDate).toDateString() != new Date().toDateString()) {
        return false;
      } else if (child.reschedule != "") {
        if(group.includes(child.reschedule.split("-")[1].split(",")[0]) && time == "" || time.includes(child.reschedule.split("-")[1].split(",")[1])) {
          return true;
        }
      }*/
      //daca reschedule != "",  new Date(child.rescheduleDate).toDateString() != new Date().toDateString() si child.reschedule.split("-")[1].split(",")[0] == group si child.reschedule.split("-")[1].split(",")[1] == time return true
      //console.log("Nume copil" + child.name + "Data pulii:" +  new Date(child.rescheduleDate).toDateString());
      //console.log("Data pizdii:" +  new Date().toDateString());
      //console.log("Ce returneaza hoascan: " + new Date(child.rescheduleDate).toDateString() == new Date().toDateString())
      if (child.reschedule != "" && new Date(child.rescheduleDate).toDateString() == new Date().toDateString()) {
        if (child.reschedule.split("-")[1].split(",")[0] == group && child.reschedule.split("-")[1].split(",")[1] == time) {
          return true && locationMatch;
        }
      }
      let timeSplit = child.time.split('-');
      let groupSplit = child.group.split('-');
      if(timeSplit.length < groupSplit.length)
        for (let j = timeSplit.length; j < groupSplit.length; j++)
          timeSplit.push(timeSplit[0]);

      let timeMatch = time === '' || (timeSplit[0] == time && groupSplit[0] == group) || (timeSplit[1] == time && groupSplit[1] == group) || (timeSplit[2] == time && groupSplit[2] == group) || (timeSplit[3] == time && groupSplit[3] == group) || (timeSplit[4] == time && groupSplit[4] == group)

      return groupMatch && timeMatch && locationMatch;
    }
  };

  function rescheduleEffect(child, option) {
    //optiunea 1 pt striketrough text si optiunea 2 pt border animat
	if (child.reschedule == "" || child.rescheduleDate == null) return '';
	let reArray = child.reschedule.split("-");
	let firstDay = reArray[0].split(",");
	let secondDay = reArray[1].split(",");
	//let datacopil = new Date(child.rescheduleDate).setHours(6);
	//daca ce e selectat in dropdown e aceiasi cu grupa lui din reprogramare e taiat
  //daca ce e selectat in dropwdown este ce e in a doua parte a reprogramarii e animat
	//cazul cand grupa lui e aceiasi cu grupa reprogramata Ex: Ma - Ma
  let groupsChild =child.group.split('-');
  let timesChild = child.time.split('-');
  if (timesChild.length < groupsChild.length)
    for (let i = timesChild.length; i < groupsChild.length; i++) //de vazut
      timesChild.push(timesChild[0]);

	if (new Date(child.rescheduleDate).toDateString() !== new Date().toDateString() && groupsChild.includes(group) && timesChild.includes(time)) {
    if(option === 1) {
      return "line-through";
    } else {
      return '';
    }
  } else if (group == secondDay[0] && time == secondDay[1]) {
        if (option === 1) {
            return "none";
        } else {
            if (child.rescheduleDate != null && new Date(child.rescheduleDate).toDateString() == new Date().toDateString())
              return 'pulsating-borderzero border-success border';

        }
  }
  /*if (option === 1 && new Date(child.rescheduleDate).toDateString() != new Date().toDateString()) {
    return "line-through";
  } else {
    if (child.rescheduleDate != null && new Date(child.rescheduleDate).toDateString() == new Date().toDateString())
      return 'pulsating-borderzero border-success';
  }
	if(option === 1) {
		//console.log("nu aici");
		return "none";
	} else {
		return '';
	}
  }*/
}

  async function handleUpdate() {
      let id = selectedChild._id;

      await axios.get(process.env.REACT_APP_API_URL + "/attend/all/admin", {
          headers: {
              'Authorization': `Bearer ${token}` 
          }
      }).then(async (res) => {
          setChildren(res.data);
          setSelectedChild(res.data.find((ch) => ch._id == id));

          // Check if there is a child, with free membership, that has TBD group or time
          let tbdChildren = getTBDChildren(res.data);
          setTbdChildren(tbdChildren);
      });
  }

  const handleChildPageButtonClick = () => {
    setScrollPosition(window.scrollY);
    window.localStorage.setItem("scrollPosition", window.scrollY);
  };

  // Return how many days ago was the first attendance or 0 if there is no attendance
  const childFirstAttendance = (child) => {
    // Get first present attendance
    const firstAttendance = child.attendance.find((att) => att.status === 'PRESENT');

    // If there is no attendance, return 0
    if (!firstAttendance) {
      return 0;
    }

    // Calculate how many days ago was the first attendance
    const today = new Date();
    const firstAttendanceDate = new Date(firstAttendance.date);
    const diffTime = Math.abs(today - firstAttendanceDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays;
  }
    

  return (
    <div className="page no-padding-top">
      {JSON.stringify(selectedChild) === '{}' ? (
        <>
          <div className="mx-md-5 py-3 text-md-start">
            <a href="/">
              <img src={logo} className="logo-search" alt="Logo" />
            </a>
          </div>
          <Row className="m-0">
            <Col lg={4} md={6} className="">
              <form onSubmit={(e) => { e.preventDefault(); }}>
                <input
                  type="text"
                  value={search}
                  onChange={(val) => setSearch(val.target.value)}
                  className="search-bar"
                />
                <p>{/* ... */}</p>
              </form>
              <div>
                <DropdownContainer onChange={handleDropdownChange} defaultDay={group} defaultTime={time} defaultLocation={attendeeLocation} role={userType}/>
                <Form className='w-100 mb-4 m-auto text-center'>
                  <Row className='justify-content-around'>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <Form.Check 
                          type="checkbox"
                          id='default-checkbox1'
                          label={
                              <span className='fst-italic text-warning'>
                                  Doar validați
                              </span>
                          }
                          defaultChecked={showValid}
                          className="my-4 m-auto text-break text-end text-light"
                          onChange={(e) => {setShowValid(e.target.checked)}}
                      />
                    </Col>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <Form.Check 
                          type="checkbox"
                          id='default-checkbox2'
                          label={
                              <span className='fst-italic text-warning'>
                                  Doar ascunși
                              </span>
                          }
                          checked={showHidden}
                          defaultChecked={showHidden}
                          className="my-4 m-auto text-break text-end text-light"
                          onChange={(e) => {setShowHidden(e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row className='justify-content-around'>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <Form.Check 
                          type="checkbox"
                          id='default-checkbox3'
                          label={
                              <span className='fst-italic text-warning position-relative'>
                                  Doar Nevalidați 

                                  {tbdChildren.length > 0 && (
                                    // Warning yellow icon
                                    <img
                                      src={WarnIconRed}
                                      alt="warning"
                                      className="warning-icon position-absolute ms-1 pulsating-warning"
                                      style={{ width: "20px", height: "20px", }}
                                    />
                                  )}
                              </span>
                          }
                          defaultChecked={showInvalid}
                          className="my-4 m-auto text-break text-end text-light"
                          onChange={(e) => {setShowInvalid(e.target.checked)}}
                      />
                    </Col>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <Form.Check 
                          type="checkbox"
                          id='default-checkbox4'
                          label={
                              <span className='fst-italic text-warning'>
                                  Doar Neascunși
                              </span>
                          }
                          checked={showUnhidden}
                          defaultChecked={showUnhidden}
                          className="my-4 m-auto text-break text-end text-light"
                          onChange={(e) => {setShowUnhidden(e.target.checked)}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <span className='fst-italic text-warning'>
                        Numarul de copii: {children.filter((child) => child.validated && ((userType === "OWNER" && child.location === attendeeLocation.toUpperCase()) || (userType === "ADMIN" && child.location === userLocation.toUpperCase()) || (userType === "OWNER" && attendeeLocation === ''))).length}
                      </span>
                    </Col>
                    <Col xs={7} sm={5} md={7} lg={10} xl={8} xxl={5}>
                      <span className='fst-italic text-white'>
                        Copii prezenti: 
                      </span>
                      &nbsp;
                      <span className='fst-italic text-success'>
                      {children.filter((child) => checkAttendance(child)).length}
                      </span>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={8} md={6}>
              <h1 className="text-break mt-md-4 mb-4 fw-bold fst-italic">
                Lista copiilor înscriși la TRY Academy
              </h1>
              <div className="d-flex flex-wrap justify-content-center">
                { children
                  .filter((child) => (!showValid && !showInvalid) || (showValid && child.validated && !child.hidden) || (showInvalid && !child.validated && !child.hidden))
                  .filter((child) => (!showHidden && !showUnhidden) || (showHidden && child.hidden) || (showUnhidden && !child.hidden))
                  .filter((child) => {
                    const searchWords = search.toLowerCase().split(/\s+/);
                  
                    const nameFields = [child.name, child.cleanName]; // List of fields to search
                  
                    return nameFields.some((nameField) => {
                      const nameWords = nameField.toLowerCase().split(/\s+/);
                      return searchWords.every((word) => nameWords.some((nameWord) => nameWord.includes(word)));
                    });
                  })
                  .filter(filterChildren)
                  .sort((a, b) => {
                    if(!showInvalid){
                      // First users with 0 attendance
                      if (a.attendanceNo === 0 && b.attendanceNo !== 0) {
                        return -1;
                      } else if (a.attendanceNo !== 0 && b.attendanceNo === 0) {
                        return 1;
                      } else if (a.attendanceNo === 0 && b.attendanceNo === 0) {
                        return a.name.localeCompare(b.name);
                      } else {
                        // Then users with 1 attendance
                        if (a.attendanceNo === 1 && b.attendanceNo !== 1) {
                          return -1;
                        } else if (a.attendanceNo !== 1 && b.attendanceNo === 1) {
                          return 1;
                        } else if (a.attendanceNo === 1 && b.attendanceNo === 1) {
                          return a.name.localeCompare(b.name);
                        } else {
                          // Finally by name
                          return a.name.localeCompare(b.name);
                        }
                      }
                    } else {
                      // Do not sort
                      return 0;
                    }
                  })
                  .map((child) => (
                    <button
                        key={child.name + child.parentName}
                        className={`border-4 rounded-5 mx-2 my-2 child-container d-flex align-items-center justify-content-center ${child.hidden ? 'hidden' : ''}
                            ${(child.rescheduleDate !== null && (child.paymentStatus === 'PARTIAL' || (child.paymentStatus === 'TBD' && child.validated === true))) ? 'border-HalfStyle' :
                            (child.rescheduleDate !== null && child.attendanceNo === 1) ? 'pulsating-border-JumiUlt' :
                            (child.rescheduleDate !== null && child.attendanceNo === 0) ? 'pulsating-border-JumiZero' :
                            ((child.paymentStatus === 'PARTIAL' || (child.paymentStatus === 'TBD' && child.validated === true)) && child.attendanceNo !== 0) ? 'pulsating-border-alabala border-dottedP' :
                            ((child.paymentStatus === 'PARTIAL' || child.paymentStatus === 'TBD') && (child.membership !== 'GRATIS' && child.membership !== 'TBD') && child.attendanceNo === 0 && child.validated === false) ? 'pulsating-border-alabala border-dottedP' :
                            //(child.paymentStatus !== 'TBD' && (child.attendanceNo === 0 || child.attendanceNo === 1) && child.membership !== 'GRATIS' && child.validated === true) ? 'pulsating-border-alabala border-dottedP' :
                            (child.paymentStatus === 'TBD' && child.validated === true ) ? 'pulsating-border-alabala border-dottedP' :
                            (child.paymentStatus !== 'TBD' && child.attendanceNo === 0 && child.membership !== 'GRATIS') ? 'pulsating-borderzero border-danger border' :
                            (child.paymentStatus !== 'TBD' && child.attendanceNo === 1 && child.membership !== 'GRATIS') ? 'pulsating-bordergrench border-warning border' :
                            (child.paymentStatus !== 'PARTIAL' && child.paymentStatus !== 'TBD' && rescheduleEffect(child, 2)) ? rescheduleEffect(child, 2):
                            'border'}
                        `}
                        onClick={() => {
                            setSelectedChild(child);
                            window.localStorage.setItem('selectedChildId', child._id);
                            handleChildPageButtonClick();
                        }}
                    >

                        {/* Warning yellow icon if first attendance was long ago */}
                        { childFirstAttendance(child) >= 27 && (
                          <img
                          src={childFirstAttendance(child) < 30 ? WarnIconYellow : WarnIconRed}
                          alt="warning"
                          className="warning-icon"
                          />
                        )}

                        <p style={{ color: getClassByMembership(child.membership), fontSize: "20px", textDecoration: rescheduleEffect(child, 1) }} className='mb-0'>
                          {child.name}
                        </p>


                        { child.attendance.find((att) => { return (new Date(att.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) && att.status === "PRESENT")}) &&
                          <img alt="check" src={check} className='h-25 check-attendance'/>
                        }
                        { child.attendance.find((att) => { return (new Date(att.date).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0) && att.status === "MISSED")}) &&
                          <img alt="absent" src={absent} className='h-25 check-attendance'/>
                        }

                        {/* If selected invalid children and is TBD, show warning icon */}
                        {showInvalid && tbdChildren.includes(child) && (
                          <img
                            src={WarnIconYellow}
                            alt="warning"
                            className="warning-icon position-absolute"
                            style={{ width: "20px", height: "20px", top: "5px", right: "5px" }}
                          />
                        )}

                      {(child.membership === 'MINI-GRUPA' || child.membership === 'PT') && child.coachcomments && (
                        <p className='text-white m-0 position-absolute  fixed-bottom' style={{ fontSize: "12px" }}>
                          {child.coachcomments}
                        </p>
                      )}
                    </button>
                  ))}
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <ChildPage
          children={children}
          child={selectedChild}
          closeFunc={handleClose}
          updateFunc={handleUpdate}
          dayDataByTime={overview[selectedChild.location]}
        />
      )}
    </div>
  );
}

export default SearchChild;