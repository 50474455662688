import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../images/logo_text.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import star from '../images/star.png';
import level1 from "../images/level1.png";
import level2 from "../images/level2.png";
import level3 from "../images/level3.png";
import level4 from "../images/level4.png";
import level5 from "../images/level5.png";
import level6 from "../images/level6.png";
import level7 from "../images/level7.png";
import './Child.css'

function ChildRanking({rank, name, points, childRank, finalRank}) {
    const ranks = [level1, level2, level3, level4, level5, level6, level7, level7];
    const ranksToString = ["Lvl.1", "Lvl.2", "Lvl.3", "Lvl.4", "Lvl.5", "Lvl.6", "Lvl.7", "Lvl.7"];

    return(
        <div className="fw-bold fs-4 w-100">
            <Row className={'m-auto py-2 ' + (finalRank ? 'bg-danger' : '')}>
                <Col xs={3} lg={2} className='text-center border border-danger border-3 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>#{rank}</p>
                </Col>
                <Col xs={5} lg={6} className='text-center border-start-0 border border-danger border-3 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>{name.split(" ")[0] + " " + name.split(" ")[1].substring(0,3) + "."}</p>
                </Col>
                <Col xs={2} className='text-center border-start-0 border border-danger border-3 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>{points}</p>
                </Col>
                <Col xs={2} className='text-center border-start-0 border border-danger border-3 py-2 px-1'>
                    <p className='m-0'>{ranksToString[childRank - 1]}</p>
                    <img src={ranks[childRank - 1]} className='small-rank'/>
                </Col>
            </Row>
        </div>
    );
}

export default ChildRanking;