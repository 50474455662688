import React, { useEffect, useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import "./Dropdown.css";

function DropdownContainer({ onChange, defaultDay, defaultTime, defaultLocation, role }) {
	const [selectedDay, setSelectedDay] = useState(defaultDay);
	const [selectedTime, setSelectedTime] = useState(defaultTime);
	const [selectedLocation, setSelectedLocation] = useState(defaultLocation);

	const generateTimeOptions = (groupla) => {
        if (groupla === 'Sa') {
            console.log('sambata');
            return ['11:00', '12:00', '13:00', '14:00', '15:00'];
        } else {
            return ['16:00', '17:00', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30'];
        }
    }
	
	const generateTimeOptions2 = (groupla) => {
        if (groupla === 'Floreasca') {
            console.log('sambata');
            return ['18:30', '19:30', '20:30'];
        } else {
            return ['16:00', '17:00', '18:00', '19:00', '20:00'];
        }
    }

	useEffect(() => {
		setSelectedDay(defaultDay);
		setSelectedTime(defaultTime);
		setSelectedLocation(defaultLocation);
	}, [defaultDay, defaultTime, defaultLocation, selectedDay, selectedTime, selectedLocation]);

	const handleDayChange = (e) => {
		const day = e;
		setSelectedDay(day);
		onChange('day', day);
	};
	const handleTimeChange = (e) => {
		const time = e;
		setSelectedTime(time);
		onChange('time', time);
	};
	const handleLocationChange = (e) => {
		const location = e;
		setSelectedLocation(location);
		onChange('location', location);
	};

	function dayCode(day) {
		switch(day) {
			case 'Lu':
				return "Luni";
			case 'Ma':
				return "Marți";
			case 'Mi':
				return "Miercuri";
			case 'Jo':
				return "Joi";
			case 'Vi':
				return "Vineri";
			case 'Sa':
				return "Sâmbătă";
			default:
				return "Oricare";
		}
	}

	return (
		<div className="text-center mt-3 mb-4">
			{role == "OWNER" &&
				<DropdownButton onSelect={handleLocationChange} title={selectedLocation == '' ? "Selectează locația" : selectedLocation } size="lg" variant="light" id="dropdown-location" className="my-md-5 mb-4">
					<Dropdown.Item eventKey="">Oricare</Dropdown.Item>
					<Dropdown.Item eventKey="Otopeni">Otopeni</Dropdown.Item>
					<Dropdown.Item eventKey="Dobroesti">Dobroești</Dropdown.Item>
				</DropdownButton>
			}
			<DropdownButton onSelect={handleDayChange} title={selectedDay == '' ? "Selectează ziua" : dayCode(selectedDay)} size="lg" variant="light" id="dropdown-day" className="my-md-5 mb-4">
				<Dropdown.Item eventKey="">Oricare</Dropdown.Item>
				<Dropdown.Item eventKey="Lu">Luni</Dropdown.Item>
				<Dropdown.Item eventKey="Ma">Marți</Dropdown.Item>
				<Dropdown.Item eventKey="Mi">Miercuri</Dropdown.Item>
				<Dropdown.Item eventKey="Jo">Joi</Dropdown.Item>
				<Dropdown.Item eventKey="Vi">Vineri</Dropdown.Item>
				<Dropdown.Item eventKey="Sa">Sâmbătă</Dropdown.Item>
			</DropdownButton>
			<DropdownButton onSelect={handleTimeChange} title={selectedTime == '' ? "Selectează ora " : selectedTime} size="lg" variant="light" id="dropdown-time" className="my-md-5 my-4">
				<Dropdown.Item eventKey="">Oricare</Dropdown.Item>
				{generateTimeOptions2(selectedLocation).map((time) => {
                                 return <Dropdown.Item eventKey={time}>{time}</Dropdown.Item>;
                            })}
			</DropdownButton>
		</div>
	);
}

export default DropdownContainer;

