import Form from 'react-bootstrap/Form';

function RulesDobroesti({ checkCounter }) {
    return (
        <Form className='mx-md-5'>
            <Form.Check 
                type="checkbox"
                id='default-checkbox1'
                label={<span>
                    <span className='fst-italic text-warning'>
                        1. DATE DE IDENTIFICARE:
                    </span>
                    <span className='small-font'> toate datele mele de identificare, respectiv ale minorului(ei) al cărui/cărei părinte/tutore sunt, au caracter complet și real. MITZUU MEDIA SRL își rezervă dreptul de a solicita un act de identitate la intrarea în incinta Pro Swimming Wellness, în care își desfășoară activitatea, pentru a verifica datele minorilor peste 14 ani și/sau ale părintelui/tutorelui însoțitor al minorului(ei).
                    </span>
                </span>}
                className="my-4 mx-4 text-break text-start text-light"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox2'
                label={<span>
                    <span className='fst-italic text-warning'>
                    2. ACCEPTAREA PREZENTULUI ACORD:
                    </span>
                    <span className='small-font'> am citit și înțeles în întregime fiecare prevedere din prezentul acord, pe care o accept în mod expres, precum și acordul, în ansamblul său, și că, prin urmare, sunt de acord cu conținutul și efectele sale.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox3'
                label={<span>
                    <span className='fst-italic text-warning'>
                    3. ACCEPTAREA REGULAMENTULUI MITZUU MEDIA SRL:
                </span>
                <span className='small-font'> am citit și înțeles în întregime fiecare prevedere din Regulamentul de participare la toate/oricare dintre activitățile organizate de MITZUU MEDIA SRL în Pro Swimming Wellness (denumit în continuare Regulamentul), ce poate fi consultat la locația fizică Pro Swimming Wellness Dobroești, și că, prin urmare, sunt de acord cu conținutul și efectele sale. I-am explicat minorei/minorului/minorelor/minorilor, în raport cu vârstă și gradul sau/lor de înțelegere, fiecare prevedere din Regulamente.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox4'
                label={<span>
                    <span className='fst-italic text-warning'>
                    4. ACCEPTARE NOTĂ INFORMARE PRIVIND PRELUCRAREA DATELOR:
                </span>
                <span className='small-font'> am citit și înțeles în întregime fiecare prevedere din Notă de informare privind prelucrarea datelor cu caracter personal, ce poate fi consultat pe website la următorul link: Notă informare și că, prin urmare, sunt de acord cu conținutul și efectele sale.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox5'
                label={<span>
                    <span className='fst-italic text-warning'>
                    5. RESPECTAREA INSTRUCȚIUNILOR MITZUU MEDIA SRL:
                </span>
                <span className='small-font'> voi desfășura, respectiv minorul(a) va desfășura toate/oricare dintre activitățile organizate de MITZUU MEDIA SRL în Pro Swimming Wellness, cu respectarea tuturor prevederilor Regulamentului, precum și, în egală măsură, cu respectarea tuturor instrucțiunilor personalului/colaboratorilor MITZUU MEDIA SRL aflați în Pro Swimming Wellness și cu respectarea tuturor instrucțiunilor afișate în incinta bazinului, sub formă de Regulament al complexului de natație.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox6'
                label={<span>
                    <span className='fst-italic text-warning'>
                    6. ELIMINAREA DIN Pro Swimming Wellness:
                    </span>
                    <span className='small-font'> am înțeles că, în situația oricărei abateri de la oricare dintre prevederile Regulamentului și/sau instrucțiunile personalului/colaboratorilor MITZUU MEDIA SRL ori instrucțiunilor afișate în locul desfășurării fiecărei activități, acolo unde este cazul, pot fi eliminat(ă), respectiv minorul(a) poate fi eliminat(ă) din Pro Swimming Wellness, fără a avea dreptul la restituirea contravalorii plătite pentru ședințele de înot.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox7'
                label={<span>
                    <span className='fst-italic text-warning'>
                    7. STARE FIZICĂ ȘI MENTALĂ STABILĂ:
                    </span>
                    <span className='small-font'> (1) am vârsta necesară (minim 2 ani), iar starea de sănătate fizică și mintală, precum și condiția fizică îmi permite să desfășor; respectiv are, iar starea de sănătate fizică și mintală, precum și condiția fizică a minorului(ei) îi permite să desfășoare toate/oricare dintre activitățile organizate de MITZUU MEDIA SRL în Pro Swimming Wellness, în special am/are disciplină adecvată, autoevaluarea și evaluarea riscurilor; (2) nu sunt sub influența alcoolului, drogurilor, stupefiantelor sau a altor substanțe/medicație care pot afecta sănătatea mintală sau fizică; (3) nu am/are afecțiuni medicale care pot afecta sănătatea mintală sau fizică; (4) nu am/are afecțiuni medicale care pot fi agravate de activitățile organizate de MITZUU MEDIA SRL în Pro Swimming Wellness; (5) nu am/are afecțiuni medicale care pot afecta capacitatea de a urma instrucțiunile personalului/colaboratorilor MITZUU MEDIA SRL; (6) nu am/are afecțiuni medicale care pot afecta capacitatea de a urma instrucțiunile afișate în incinta bazinului, sub formă de Regulament al complexului de natație.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox8'
                label={<span>
                    <span className='fst-italic text-warning'>
                    8. RECUNOAȘTEREA PERICOLELOR, UTILIZAREA PE PROPRIUL RISC, SUPORTAREA DAUNELOR:
                    </span>
                    <span className='small-font'> Sunt conștient de faptul că participarea la cursurile sportive din cadrul Pro Swimming Wellness poate implica și riscuri de accidentare și suportă un risc crescut de accident în cazul în care îmi supraevaluez abilitățile fizice și psihice personale, respectiv ale minorului(ei). Mă angajez să evit orice pericol disproporționat. Mă angajez să raportez orice acțiune a terților aflați în incinta Pro Swimming Wellness care cauzează un pericol disproporționat. Accept înscrierea în cursurile de înot MITZUU MEDIA SRL pe propriul meu risc. Înțeleg și accept în mod expres faptul că răspunderea MITZUU MEDIA SRL poate fi angajată doar în cazul în care MITZUU MEDIA SRL a cauzat un prejudiciu prin acțiunea sau inacțiunea sa, în condițiile legii. Înțeleg și accept în mod expres faptul că MITZUU MEDIA SRL nu poate fi făcută responsabilă pentru prejudiciile cauzate de terți aflați în incinta Pro Swimming Wellness, precum și pentru prejudiciile cauzate de acțiunile sau inacțiunile altor persoane aflate în incinta Pro Swimming Wellness. Înțeleg și accept în mod expres faptul că MITZUU MEDIA SRL nu poate fi făcută responsabilă pentru prejudiciile cauzate de acțiunile sau inacțiunile altor persoane aflate în incinta Pro Swimming Wellness, precum și pentru prejudiciile cauzate de terți aflați în incinta Pro Swimming Wellness.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox9'
                label={<span>
                    <span className='fst-italic text-warning'>
                    9. CONSIMȚĂMÂNT GDPR:
                    </span>
                    <span className='small-font'> sunt de acord că este posibil ca înregistrări video ori fotografii de grup care reproduc imaginea mea și/sau a/ale minorului(ei) (înfățișarea fizică) să fie efectuate și folosite de MITZUU MEDIA SRL în scopul promovării produselor și serviciilor sale în mediul online pentru postarea pe site-ul www.tryacademy.ro, www.mitzuu.ro , precum și pe diferite rețele de socializare: Facebook, Google, LinkedIn, Youtube, Instagram care aparțin MITZUU MEDIA SRL.</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
            <Form.Check 
                type="checkbox"
                id='default-checkbox10'
                label={<span>
                    <span className='fst-italic text-warning'>
                    10. VALABILITATEA (DURATA) ABONAMENTULUI:
                    </span>
                    <span className='small-font'> sunt de acord și am luat la cunoștință faptul că valabilitatea (durata) unui abonament (pachet) cu una sau mai multe ședințe de înot este de 30 de zile. În cazul în care cursantul/cursanta este înscris(ă) la un abonament de Mini-Grupa sau PT (Antrenor Personal) si lipsește o ședință sau mai multe, acestea sunt considerate pierdute/efectuate din cauza rezervarii unui antrenor dedicat pentru cursantul dvs. si pentru blocarea locului la grupa respectiva. În cazul în care cursantul/cursanta este înscris(ă) la un abonament de Grupa si lipsește o ședință sau mai multe,pentru a nu le pierde, acesta(acestea) este(sunt) nevoit(ă) să anunțe personalul MITZUU MEDIA SRL / TRY ACADEMY înainte cu minim 24 ore de la ora cursului programat și să recupereze ședințele în săptămânile rămase până la terminarea celor 30 de zile ale abonamentului. Ședințele de recuperare se pot efectua doar în orele în care au loc cursurile de obicei, (Luni-Vineri 17:00-20:00) exceptând situații în care există competiții în bazin iar programul precizat mai înainte este alterat. Neprezentarea la ședința reprogramată, duce la considerarea acesteia ca fiind expirată, cursantul fiind marcat prezent. Confirmarea prezenței a cursantului la o ședință și neprezentarea la aceasta are ca și consecință marcarea cursantului ca "Prezent". Totodată, programarea ședințelor se face cu un acord expres prealabil scris din partea personalului MITZUU MEDIA SRL. În caz contrar, ședințele neefectuate la finalul celor 30 de zile ale abonamentului se vor considera nevalabile. (expirate).</span>
                </span>}
                className="my-4 mx-4 text-break text-light text-start"
                onChange={(e) => checkCounter(e.target.checked)}
            />
        </Form>
    );
}

export default RulesDobroesti;