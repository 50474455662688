import '../../App.css';
import { useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import './Reschedule.css';
import star from '../images/star.png';


function Reschedule({ reschedule, admin, handleUpdate}) {
    let userType = window.localStorage.getItem("userType");
    const token = window.localStorage.getItem("token");
    const borderColor = 'border-white';
    const [show, setShow] = useState(false);
    const points = reschedule.status == 'PRESENT' ? '+5' : (reschedule.substractPoints ? '-3' : '0');
    function handleDelete() {
        axios.delete(process.env.REACT_APP_API_URL + `/attend/attendance/${reschedule._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res);
            handleUpdate();
        }).catch(err => {
            console.log(err);
        });
    }

    return(
        <div className={'reschedule border border-4 rounded-5 attendance mx-2 my-2 text-break d-flex flex-column justify-content-center py-3 ' + borderColor}>
            <p className="title mb-1">REPROGRAMARE</p>
            <p className='mb-1'>Data: {moment(reschedule.date).format("DD/MM")}</p>
            <p className='mb-1'>Status:  
                {reschedule.status == 'PRESENT' ? <span className="text-prezent"> Prezent</span> : <span className='text-absent'> Absent</span>}
            </p>
            {admin && <div><TryButton text="Șterge" onClick={() => setShow(true)}/></div>}
            {userType !== "COACH" && <p className='mb-0 mt-2 h6'>{points} puncte</p>}

            {reschedule.goldenBuzz &&
                <img alt='star' src={star} className='star-attendance'/>
            }

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Șterge reprogramarea</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sigur doriți să ștergeți reprogramarea din data de {moment(reschedule.date).format("DD/MM/yyyy")}?</p>
                    <div className="d-flex justify-content-end">
                        <TryButton text="Anulează" onClick={() => setShow(false)} className="mx-3"/>
                        <TryButton text="Șterge" onClick={handleDelete}/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Reschedule;