import '../../App.css';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../images/logo_text.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import './Child.css'
import './ChildRanking.css'

function ChildRankingAdmin({rank, name, points, group, time, childRank, finalRank, _id, giftGiven, location}) {
    const pointsInterval = [0, 40, 105, 245, 370, 605, 1200];
    const incomingPrize = ["Dip+Bom", "Clip", "Merch", "Film", "VIP", "Tel"]
    const navigate = useNavigate();

    const handleClick = () => {
        localStorage.setItem("selectedChildId", _id);
        if(location == "OTOPENI")
            localStorage.setItem("goRanking", "1");
        else if(location == "DOBROESTI")
            localStorage.setItem("goRanking", "2");
        navigate(`/search`);
    }
    return(
        //console.log("childRank: " + childRank + " giftGiven: " + giftGiven + " name: " + name),
        <div className="fw-bold fs-md-5 fs-6">
            <Row className={'m-auto py-2 ' + (finalRank ? 'bg-danger' : '')}>
                <Col xs={2} sm={1} className='text-center border border-danger border-2 py-2 px-0 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>#{rank}</p>
                </Col>
                <Col xs={4} sm={5} className='text-center border-start-0 border border-danger border-2 py-2 px-1' onClick={handleClick}>
                    <div className='text-center d-flex flex-column align-items-center w-100'>
                        <p className={`m-0 w-100  ${(points >= pointsInterval[childRank] - 15 && points < pointsInterval[childRank] - 5) ? 'yellow-pulse' : ''} ${(points >= (pointsInterval[childRank] - 5) && points <= pointsInterval[childRank]) ? 'red-custom' : ''} ${((childRank - 1) != giftGiven) ? 'red-pulse' : ''}`}> {name.split(" ")[0] + " " + name.split(" ")[1]} </p>
                        <p className='m-0 w-100'>{group != 'TBD' ? group.split("-").map((e) => e[0]).join('-') : group} {time != 'TBD' ? time.split("-").map((e) => e.replace(":00", "")).join("-") : time}</p>
                    </div>
                </Col>
                <Col xs={3} className='text-center border-start-0 border border-danger border-2 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>{points}/{pointsInterval[childRank]}</p>
                </Col>
                { (childRank - 1) == giftGiven &&
                <Col xs={3} className='text-center border-start-0 border border-danger border-2 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>{incomingPrize[childRank - 1]}</p>
                </Col>
                }

                { (childRank - 1) != giftGiven &&
                <Col xs={3} className='text-center border-start-0 border border-danger border-2 py-2 px-1 d-flex flex-row align-items-center'>
                    <p className='m-0 w-100'>{incomingPrize[giftGiven]}</p>
                </Col>
                }
            </Row>
        </div>
    );
}
export default ChildRankingAdmin;