import {Button} from "react-bootstrap";
import "./TryButton.css";

function TryButton({type, className, disabled, text, onClick}) {
    return (
        <button className={`redButton ${className}`} type={type} disabled={disabled} onClick={onClick}>
            {text}
        </button>
    );
}
export default TryButton;