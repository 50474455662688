import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useState } from "react";

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [ date, setDate ] = useState(new Date());

  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val);
      }}
      onYearChange={(date) => {setDate(date); setFieldValue(field.name, date)}}
      onMonthChange={(date) => {setDate(date); setFieldValue(field.name, date)}}
      onWeekSelect={(date) => setDate(date)}
      onDayChange={(date) => setDate(date)}
      openToDate={date}
    />
  );
};