import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from "../images/logo_text.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import star from '../images/star.png';

function Attendance({ attendance, admin, handleUpdate, attendanceMembership}) {
    let userType = window.localStorage.getItem("userType");
    const token = window.localStorage.getItem("token");
    const borderColor = attendanceMembership == 'GRATIS' ? 'border-warning' : (attendance.status == 'PRESENT' ? 'border-success' : 'border-danger');
    const points = attendance.status == 'PRESENT' ? '+5' : (attendance.substractPoints ? '-3' : '0');
    const [show, setShow] = useState(false);
    function handleDelete() {
        axios.delete(process.env.REACT_APP_API_URL + `/attend/attendance/${attendance._id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res);
            handleUpdate();
        }).catch(err => {
            console.log(err);
        });
    }

    return(
        <div className={'border border-4 rounded-5 attendance mx-2 my-2 text-break d-flex flex-column justify-content-center pt-3 pb-1 ' + borderColor}>
            <p className='mb-1'>Data: {moment(attendance.date).format("DD/MM/yyyy")}</p>
            <p className='mb-1'>Status: {attendance.status == 'PRESENT' ? "Prezent" : "Absent"}</p>
            {admin && <div><TryButton text="Șterge" onClick={() => setShow(true)}/></div>}
            {userType !== "COACH" && <p className='mb-0 mt-2 h6'>{points} puncte</p>}
            {attendance.goldenBuzz &&
                <img alt='star' src={star} className='star-attendance'/>
            }
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Șterge prezența</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Sigur doriți să ștergeți prezența din data de {moment(attendance.date).format("DD/MM/yyyy")}?</p>
                    <div className="d-flex justify-content-end">
                        <TryButton text="Anulează" onClick={() => setShow(false)} className="mx-3"/>
                        <TryButton text="Șterge" onClick={handleDelete}/>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Attendance;