import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import logo from "../images/logo_text.png";
import { useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import Attendance from './Attendance';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from '../form/DatePickerField';
import './Child.css';
import { useParams } from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import level1 from "../images/level1.png";
import level2 from "../images/level2.png";
import level3 from "../images/level3.png";
import level4 from "../images/level4.png";
import level5 from "../images/level5.png";
import level6 from "../images/level6.png";
import level7 from "../images/level7.png";
import backgroundlevels from "../images/backgroundlevels.jpg";
import ChildRanking from './ChildRanking';
import ReactPaginate from 'react-paginate';

function ChildPageSeparate() {
    const admin = window.localStorage.getItem("token") != null;
    const token = window.localStorage.getItem("token");
    const { childId } = useParams();
    const [child, setChild] = useState({});
    let isMobile = window.innerWidth <= 992;
    const [showRanking, setShowRanking] = useState(false);
    const minRanks = [0, 40, 105, 245, 370, 605, 1200, 1200];
    const ranks = [level1, level2, level3, level4, level5, level6, level7, level7];
    const ranksToString = ["Lvl.1", "Lvl.2", "Lvl.3", "Lvl.4", "Lvl.5", "Lvl.6", "Lvl.7", "Lvl.7"];
    const prizes = ["Niciun premiu", "Bomboană specială + Diplomă", "CLIP special de la Mitzuu", "Merch Special", "Filmuleț cu Mitzuu", "Sedință VIP cu Mitzuu" , "TELEFON nou", "TELEFON nou"];
    const [showLeaderboard, setShowLeaderboard] = useState(false);
    const [leaderboard, setLeaderboard] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);  
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [pageCount, setPageCount] = useState(0);

    useEffect(() => {
        // get child from here
        console.log(currentPage);
        if(childId && !child._id) {
            console.log(childId);
            axios.get(process.env.REACT_APP_API_URL + `/attend/link/${childId}`).then(res => {
                // Hide last characters of email, phone
                res.data.email = res.data.email.substring(0, res.data.email.length - 10) + "**********";
                res.data.phoneNumber = res.data.phoneNumber.substring(0, res.data.phoneNumber.length - 6) + "******";

                // Show only first name of parent and first 3 characters of last name
                res.data.parentName = res.data.parentName.split(" ")[0] + " " + res.data.parentName.split(" ")[1].substring(0, 3) + ".";

                setChild(res.data);
                console.log(res.data);
            }).catch(err => {
                console.log(err);
            });
        }
    }, [JSON.stringify(child), currentPage]);

    return(
        <div className="page no-padding-top">
            <h1 className='m-auto pt-5 pb-5 fw-bold fst-italic'>{child.name}</h1>
            <Row className='text-center mx-md-5 mx-4'>
                <Col md={6}>
                    <div className='white-header'>Date generale</div>
                    { admin && <p>Data nașterii: {moment(child.birthday).format("DD/MM/yyyy")}</p>}
                    <p>Nume părinte: {child.parentName}</p>
                    { admin && <p className='text-truncate'>Email: {child.email}</p>}
                    { admin && <p>Nr. de telefon: {child.phoneNumber}</p>}
                    <p>Data de început: {moment(child.startDate).format("DD/MM/yyyy")}</p>
                    <p>Stare plată: {child.paymentStatus}</p>
                </Col>
                <Col md={6}>
                    <div className='white-header'>Date administrative</div>
                    <p>Grupa: {child.group}</p>
                    <p>Ora: {child.time}</p>
                    <p>Nivel: {child.level}</p>
                    <p>Sedințe rămase:&nbsp;
                        <span style={{ color: child.attendanceNo === 0 ? 'red' : child.attendanceNo === 1 ? 'yellow' : 'green' }}>
                        {child.attendanceNo}
                        </span>
                    </p>
                    <p>Absențe: {child.missed}</p>
                    <p>Abonament: {child.membership}</p>
                    <p>Locatie: {child.location}</p>
                    <p>Reprogramare: {child.reschedule != '' ? child.reschedule : "Nu"}</p>
                    <p>Dată reprogramare: {child.rescheduleDate != null ? moment(child.rescheduleDate).format("DD/MM/yyyy") : "Nu"}</p>
                </Col>
                <Col xs={0} md={3}>
                </Col>
                <Col xs={12} md={6}>
                    <img src={ranks[child.rank - 1]} alt="level1" onClick={() => setShowRanking(true)} className='large-rank'/>
                    <h1 className='mb-3'>Level {child.rank}</h1>
                    <div className='d-flex w-100 justify-content-center mb-md-3 mb-5'>
                        <div className='position-relative mb-3'>
                            <img src={ranks[child.rank - 1]} alt={"level" + child.rank} className='small-rank'/>
                            <p className='rank-label text-center '>{prizes[child.rank - 1]}</p>
                        </div>
                        <ProgressBar completed={child.points ? child.points.toString() : "0"} customLabel={child.points ? child.points.toString() + "/" + minRanks[child.rank] : "0"} maxCompleted={minRanks[child.rank]} height='3vh' width='100%' margin='auto' className='d-flex w-100' labelClassName='progress-label-main' barContainerClassName='progress-container-main'/>
                        <div className='position-relative mb-3'>
                            <img src={ranks[child.rank]} alt={"level" + (child.rank + 1)} className='small-rank'/>
                            <p className='rank-label text-center '>{prizes[child.rank]}</p>
                        </div>
                    </div>
                </Col>
                <Col xs={0} md={3}>
                </Col>
                <div className='pb-3'>
                    <TryButton text="Leaderboard" onClick={() => {
                        axios.get(process.env.REACT_APP_API_URL + `/attend/leaderboard/` + child._id).then(res => {
                            setLeaderboard(res.data);
                            setPageCount(Math.ceil((res.data.length - 1) / itemsPerPage));
                            console.log(res.data);
                        }).catch(err => {
                            console.log(err);
                        });
                        setShowLeaderboard(true);
                    }} className="mx-3"/>
                </div>
                <Col xs={12}>
                    <div className='white-header'>Comentarii Antrenor</div>
                    <p>{child.coachcomments != "" ? child.coachcomments : "Antrenorul nu are comentarii."}</p>
                </Col>
                { child.attendance && child.attendance.length > 0 &&
                    <>
                        <div className='white-header'>Catalog</div>
                        <div className='d-flex flex-wrap mb-3 justify-content-md-start justify-content-center'>
                            {child.attendance.map((att) => {
                                return <Attendance key={JSON.stringify(att)} attendance={att} admin={false} />;
                            })}
                        </div>
                    </>
                }
            </Row>
            <Modal show={showRanking} onHide={() => setShowRanking(false)} fullscreen style={{backgroundImage: "url(" + backgroundlevels + ")", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
                <div style={{backgroundImage: "url(" + backgroundlevels + ")"}} className='min-vh-100 scrollable text-light'>
                    <Modal.Header closeButton className=''>
                        <Modal.Title>Detalii rank</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=''>
                        <div className={isMobile ? 'mt-4' : 'd-flex justify-content-center mt-md-5'}>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                <div className='position-relative'>
                                    <img src={level1} alt="level1" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[0]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 1 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[1]} maxCompleted={minRanks[1]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level2} alt="level2" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[1]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 2 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[2]} maxCompleted={minRanks[2]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level3} alt="level3" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[2]}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                { isMobile && 
                                    <div className='position-relative'>
                                        <img src={level3} alt="level3" className='medium-rank'/>
                                        {/*<p className='rank-label text-center '>{prizes[2]}</p>*/}
                                    </div>
                                }
                                <ProgressBar completed={child.rank >= 3 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[3]} maxCompleted={minRanks[3]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level4} alt="level4" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[3]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 4 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[4]} maxCompleted={minRanks[4]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level5} alt="level5" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[4]}</p>
                                </div>
                            </div>
                            <div className='d-flex justify-content-center mb-5 pb-5'>
                                { isMobile &&
                                    <div className='position-relative'>
                                        <img src={level5} alt="level5" className='medium-rank'/>
                                        {/*<p className='rank-label text-center '>{prizes[4]}</p>*/}
                                    </div>
                                }
                                <ProgressBar completed={child.rank >= 5 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[5]} maxCompleted={minRanks[5]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level6} alt="level6" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[5]}</p>
                                </div>
                                <ProgressBar completed={child.rank >= 6 ? (child.points ? child.points.toString() : "0") : "0"} customLabel={ (child.points ? child.points.toString() : "0") + "/" + minRanks[6]} maxCompleted={minRanks[6]} height={isMobile ? '3.5vw' : '1.5vw'} width={isMobile ? '22.5vw' : '10vw'} margin='auto' className={'d-flex px-1'} labelClassName='progress-label' barContainerClassName='progress-container'/>
                                <div className='position-relative'>
                                    <img src={level7} alt="level7" className='medium-rank'/>
                                    <p className='rank-label text-center '>{prizes[6]}</p>
                                </div>
                            </div>
                        </div>
                        <div className={isMobile ? 'w-100 m-auto px-2' : 'w-50 m-auto'}>
                            <h1 className='text-center text-warning mb-5 dropshadow-text'>Cum fac puncte?</h1>
                            <div className='mb-5'>
                                <p> 1 Prezență la TRY Academy = <span className="dropshadow-textgreen">+5</span> puncte</p>
                                <p>1 Absență = <span className="dropshadow-textred">-3</span> puncte</p>
                                <p><span className="dropshadow-textyellow">Antrenamentul de aur = +10 puncte</span></p>
                                <p>1 Lună Vechime la TRY Academy = +10 puncte</p>
                            </div>
                            <h4>Deci vino la antrenamente, fă puncte și bucură-te de cele mai tari premii EVER!</h4>
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
            <Modal show={showLeaderboard} onHide={() => setShowLeaderboard(false)} fullscreen style={{backgroundImage: "url(" + backgroundlevels + ")", backgroundSize: "cover", backgroundPosition: "center center", backgroundRepeat: "no-repeat"}}>
                <div style={{backgroundImage: "url(" + backgroundlevels + ")"}} className='min-vh-100 scrollable text-light'>
                    <Modal.Header closeButton className=''>
                        <Modal.Title>Leaderboard</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className=''>
                        <h2 className='text-center py-3 my-1 w-100 bg-danger'>
                            {child.name != null? (child.name.split(" ")[0][0].toUpperCase() + child.name.split(" ")[0].substring(1) + " " + child.name.split(" ")[1].substring(0,3) + ".") : ""} Ești Locul #{leaderboard[leaderboard.length - 1]} Cu {child.points} Puncte
                        </h2>
                        <div className={isMobile ? '' : 'w-50 m-auto'}>
                            <Row className='w-100 m-auto'>
                                <Col xs={3} lg={2} className='text-center'>
                                    <h3>Loc</h3>
                                </Col>
                                <Col xs={5} lg={6} className='text-center'>
                                    <h3>Nume</h3>
                                </Col>
                                <Col xs={2} className='text-center'>
                                    <h3>Pct.</h3>
                                </Col>
                                <Col xs={2} className='text-center'>
                                    <h3>Lvl.</h3>
                                </Col>
                            </Row>
                            {leaderboard.slice(0, -1).map((lb, index) => <ChildRanking rank={index + 1} name={lb.name} points={lb.points} childRank={lb.rank} finalRankc={false}/>).slice(currentPage * itemsPerPage, Math.min((currentPage + 1) * itemsPerPage, leaderboard.length - 1))}
                        </div>
                        <div className={isMobile ? 'w-75 m-auto' : ''}>
                            <ReactPaginate
                                nextLabel=">>>>>"
                                onPageChange={(val) => setCurrentPage(val.selected)}
                                pageRangeDisplayed={1}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                previousLabel="<<<<<"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination justify-content-center"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </div>
);}

export default ChildPageSeparate;