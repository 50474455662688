import React from 'react';
import '../../App.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal } from 'react-bootstrap';
import logo from "../images/logo_text.png";
import { createContext, useEffect, useState } from 'react';
import axios from 'axios';
import TryButton from '../button/TryButton';
import moment from 'moment';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { DatePickerField } from '../form/DatePickerField';
import { Page } from 'react-pdf';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

function OverviewPage() {
	const navigate = useNavigate();
	const admin = window.localStorage.getItem("token") != null;
	const token = window.localStorage.getItem("token");
	let userType = window.localStorage.getItem("userType");
	let userLoc = window.localStorage.getItem("userLoc");
	const [overview, setOverview] = useState({});
	const [location, setLocation] = useState(userLoc);
	const [show, setShow] = useState(false);
	const [selectedDay, setSelectedDay] = useState('');
	const [selectedTime, setSelectedTime] = useState('');
	const [children, setChildren] = useState([]);
	const dayOrder = {
	  Lu: 1,
	  Ma: 2,
	  Mi: 3,
	  Jo: 4,
	  Vi: 5,
	  Sa: 6
	};
	const hourOrder = {
		"10:00": 1,
		"11:00": 2,
		"12:00": 3,
		"13:00": 4,
		"14:00": 5,
		"15:00": 6,
		"16:00": 7,
		"17:00": 8,
		"18:00": 9,
		"18:30": 10,
		"19:00": 11,
		"19:30": 12,
		"20:00": 13,
		"20:30": 14
	};

	function getRescheduledChildren() {
		let rescheduled = children.filter((item) => {
			if(item.reschedule === "") return false;
			let [day, time] = item.reschedule.split("-")[1].split(",");
			return item.reschedule != "" && day === selectedDay && time === selectedTime && item.location === location;
		});
		return rescheduled.length > 0 ? rescheduled.map((item) => (
			<p key={item._id}>{item.name} - {moment(item.rescheduleDate).format("DD/MM/yyyy")} - {item.membership}</p>
		)) : <p>Nu există copii reprogramați!</p>;
	}
  
	useEffect(() => {
		if(Object.keys(overview).length == 0) {
			axios.get(process.env.REACT_APP_API_URL + '/attend/count', {
				headers: {
					Authorization: `Bearer ${token}`
				}
			}).then((response) => {
				setOverview(response.data);
				console.log(response.data);
				axios.get(process.env.REACT_APP_API_URL + '/attend/all/admin', {
					headers: {
						Authorization: `Bearer ${token}`
					}
				}).then((response) => {
					setChildren(response.data);
				}).catch((error) => {
				});
			}).catch((error) => {
			});
		}
		if(location == 'TBD') 
			setLocation('OTOPENI');
	}, [location]);

	return (
	  <div className="page no-padding-top">
		<h1 className="text-center pt-5 pb-3">Situația generală a grupelor</h1>
		{userType == "OWNER" &&
			<DropdownButton onSelect={(e) => setLocation(e)} title={location == '' ? "Orice locație" : location} size="lg" variant="light">
				<Dropdown.Item eventKey="OTOPENI" onSelect={window.localStorage.setItem("location", "Otopeni")}>Otopeni</Dropdown.Item>
				<Dropdown.Item eventKey="DOBROESTI" onSelect={window.localStorage.setItem("location", "Dobroesti")}>Dobroesti</Dropdown.Item>
			</DropdownButton>
		}
		<div className="pt-5">
		  <Row md={4} className="w-100 justify-content-around m-0">
			{Object.keys(overview).length != 0 && Object.keys(dayOrder).map((dayKey) => {
			  const dayName = {
				Lu: "Luni",
				Ma: "Marți",
				Mi: "Miercuri",
				Jo: "Joi",
				Vi: "Vineri",
				Sa: "Sâmbătă"
			  }[dayKey];
			  const dayStyle = {
				fontWeight: "bold",
				color: {
				  Lu: "cyan",
				  Ma: "green",
				  Mi: "red",
				  Jo: "orange",
				  Vi: "greenyellow",
				  Sa: "pink"
				}[dayKey]
			  };
  
			  return (
				<Col xs={12} md={4} key={dayKey} className='m-0'>
				  <h3 style={dayStyle} className="py-2 mx-5 border border-light border-2 rounded-3 text-center text-start">
					{dayName}
				  </h3>
				  { Object.keys(hourOrder)
				  		.filter((hour) => Object.values(overview[location][dayKey][hour]).reduce((a, b) => a + b, 0) > 0)
						.map((hour) => (
						<Row key={hour} className='m-0 justify-content-center'>
							<Col xs={3} sm={4} lg={4} className='m-0'>
								<p className="text-center text-end" onClick={() => {
									window.localStorage.setItem("group", dayKey)
									window.localStorage.setItem("time", hour)
									window.localStorage.setItem('selectedChildId', '')
									window.localStorage.setItem('location', location)
									navigate(`/search`);
								}}>{hour}</p>
							</Col>
							<Col xs={6} sm={8} lg={6} className="d-flex m-0 justify-content-center">
								<p style={{color: "aqua", fontSize:"20px"}} className="text-center text-end me-1">{overview[location][dayKey][hour]["GRUPA"]}</p>
								<p className="text-center text-end">+</p>
								<p style={{color: "chartreuse", fontSize:"20px"}} className="text-center text-end ms-1">{overview[location][dayKey][hour]["MINI-GRUPA"]}</p>
								<p className="text-center text-end">+</p>
								<p style={{color: "violet", fontSize:"20px"}} className="text-center text-end ms-1 me-1">{overview[location][dayKey][hour]["PT"]}</p>
								<p className="text-center text-end">+</p>
								<p style={{color: "orange", fontSize:"20px"}} className="text-center text-end ms-1">{overview[location][dayKey][hour]["GRATIS"]}</p>
								<p className="text-center text-end">+</p>
								<p style={{color: "green", fontSize:"20px"}} className="text-center text-end ms-1" onClick={() => {
									setSelectedDay(dayKey);
									setSelectedTime(hour);
									setShow(true);
								}}>{overview[location][dayKey][hour]["RESCHEDULE"]}</p>
							</Col>
						</Row>
						))}
				</Col>
			  );
			})}
		  </Row>
		  <div className='text-start mx-5 my-0'>
			<p className='pt-5'>LEGENDA:</p>
			<p style={{color: 'aqua'}} className='ps-3 m-0 p-2'>GRUPA</p>
			<p style={{color: 'chartreuse'}} className='ps-3 m-0 p-2'>MINIGRUPA</p>
			<p style={{color: 'violet'}} className='ps-3 m-0 p-2'>PT</p>
			<p style={{color: 'orange'}} className='ps-3 m-0 p-2'>GRATIS</p>
			<p style={{color: 'green'}} className='ps-3 m-0 p-2'>REPROGRAMĂRI</p>
		  </div>
		</div>
		<Modal show={show} onHide={() => setShow(false)}>
			<Modal.Header closeButton>
				<Modal.Title>Reprogramări</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{getRescheduledChildren()}
			</Modal.Body>
			<Modal.Footer>
				<TryButton className="greenButton" onClick={() => setShow(false)} text="OK" />
			</Modal.Footer>
		</Modal>
	  </div>
	);
  }
  
export default OverviewPage;
  